<template>
  <b-modal
    visible
    scrollable
    centered
    :title="title"
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div
      v-if="isLoading"
      class="d-flex justify-content-center w-100"
    >
      <b-spinner variant="success" />
    </div>

    <template v-else>
      <div>
        <div class="d-flex">
          <div
            v-if="!promocodeId || promocodeId === 0"
            class="mr-5"
          >
            <div class="mb-2 h6">
              Генерация промокодов
            </div>

            <b-form-checkbox
              id="checkbox-1"
              v-model="generatePromocodes"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
            >
              Сгенерировать промокоды
            </b-form-checkbox>
          </div>

          <div v-if="generatePromocodes">
            <div class="mb-2 h6">
              Количество промокодов
            </div>

            <b-form-input
              v-model.trim="promocodesCount"
              placeholder="Количество"
            />

            <div
              v-if="$v.promocodesCount.$error"
              class="mt-2 text-danger small"
            >
              {{ errorsValidation.promocodesCount[0] }}
            </div>
          </div>

          <div v-else>
            <div class="mb-2 h6">
              Название промокода
            </div>

            <b-form-input
              v-model.trim="code"
              placeholder="Введите название"
            />

            <div
              v-if="$v.promocode.code.$error"
              class="mt-2 text-danger small"
            >
              {{ errorsValidation.code[0] }}
            </div>
          </div>

          <div
            class="checkbox-queue"
            :class="{'checkbox-queue-2': !!promocodeId}"
          >
            <b-form-checkbox
              v-model="promocodeQueue"
              value="1"
              unchecked-value="0"
              :disabled="!!promocodeId"
            >
              Для очереди
            </b-form-checkbox>
          </div>
        </div>

        <div class="mt-3">
          <div class="mb-2 h6">
            Тип промокода
          </div>

          <b-form-radio-group
            v-model="promocodeType"
            :options="PROMO_CODES_TYPES"
            class="mb-3"
            value-field="alias"
            text-field="title"
            disabled-field="notEnabled"
            @input="changePromocodeType"
          />

          <div
            v-if="$v.promocodeType.$error"
            class="mt-2 text-danger small"
          >
            {{ errorsValidation.promocodeType[0] }}
          </div>
        </div>

        <div class="mt-3">
          <div class="mb-2 h6">
            Тип скидки
          </div>

          <b-form-radio-group
            v-model="priceType"
            :options="DISCOUNT_TYPE"
            class="mb-3"
            value-field="alias"
            text-field="title"
            disabled-field="notEnabled"
            @input="changePriceType"
          />
        </div>

        <div
          v-if="priceType"
          class="mt-3"
        >
          <div
            v-if="priceType === 'fixed'"
            class="w-50"
          >
            <div class="mb-2 h6">
              Покрытие {{ promocodeType === 'perc' ? '%' : 'руб.' }}
            </div>

            <b-input
              v-model.trim="$v.promocode.valuePrice.$model"
              type="number"
              placeholder="Покрытие"
            />
          </div>

          <div v-if="priceType === 'dynamic'">
            <template v-for="(consultation, index) in consultations">
              <b-row
                v-if="consultation.inputAdded"
                :key="consultation.id"
                class="mb-3"
              >
                <b-col md="6">
                  <div class="mb-2 h6">
                    Количество консультаций
                  </div>

                  <b-input
                    v-model.trim="$v.promocode[consultation.consultationsKey].$model"
                    type="number"
                    placeholder="Покрытие"
                  />
                </b-col>

                <b-col md="6">
                  <div class="mb-2 h6">
                    Покрытие {{ promocodeType === 'perc' ? '%' : 'руб.' }}
                  </div>

                  <b-input
                    v-model.trim="$v.promocode[consultation.priceKey].$model"
                    type="number"
                    placeholder="Покрытие"
                  />
                </b-col>
              </b-row>

              <b-button
                v-if="!consultation.inputAdded && consultations[index - 1].inputAdded"
                :key="consultation.id"
                variant="primary"
                :type="$const.PRIMARY_BUTTON"
                class="mt-2"
                @click="consultation.addInputAddedMethod"
              >
                Добавить скидку
              </b-button>
            </template>
          </div>

          <div
            v-if="$v.priceType.$error"
            class="mt-2 text-danger small"
          >
            {{ errorsValidation.priceType[0] }}
          </div>
        </div>
      </div>

      <div class="mt-3">
        <div class="mb-2 h6">
          Признак промокода
        </div>

        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-model="$v.promocode.attribute.$model"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            :value="0"
          >
            Мать и дитя
          </b-form-radio>
          <b-form-radio
            v-model="status"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            :value="1"
          >
            Доктис
          </b-form-radio>
          <b-form-radio
            v-model="status"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            :value="2"
          >
            Депозит
          </b-form-radio>
        </b-form-group>

        <div
          v-if="$v.promocode.attribute.$error"
          class="mt-2 text-danger small"
        >
          {{ errorsValidation.attribute[0] }}
        </div>
      </div>

      <div
        v-if="promocode.attribute === 2"
        class="mt-3"
      >
        <div class="mb-2 h6">
          Признак депозита
        </div>

        <b-form-radio-group
          v-model="$v.promocode.status.$model"
          :options="SIGN_OF_DEPOSIT"
          class="mb-3"
          value-field="id"
          text-field="title"
          disabled-field="notEnabled"
        />

        <div
          v-if="$v.promocode.status.$error"
          class="mt-2 text-danger small"
        >
          {{ errorsValidation.status[0] }}
        </div>
      </div>

      <div class="mt-3">
        <div class="mb-2 h6">
          Срок действия промокода
        </div>

        <b-form-radio-group
          v-model="termType"
          :options="PROMO_CODE_VALIDITY_PREIOD"
          class="mb-3"
          value-field="alias"
          text-field="title"
          disabled-field="notEnabled"
          @input="changeTermType"
        />

        <div
          v-if="$v.termType.$error"
          class="mt-2 text-danger small"
        >
          {{ errorsValidation.termType[0] }}
        </div>
      </div>

      <div
        v-if="termType === 'actual'"
        class="mt-3 mb-5"
      >
        <b-row>
          <b-col md="6">
            <div class="mb-2 h6">
              Дата начала
            </div>

            <base-date-picker
              v-model="$v.promocode.startDate.$model"
              :error="$v.promocode.startDate.$error"
              :errors="errorsValidation.startDate"
            />
          </b-col>

          <b-col md="6">
            <div class="mb-2 h6">
              Дата окончания
            </div>

            <base-date-picker
              v-model="$v.promocode.endDate.$model"
              :error="$v.promocode.endDate.$error"
              :errors="errorsValidation.endDate"
            />
          </b-col>
        </b-row>
      </div>

      <div class="mt-3">
        <div class="mb-2 h6">
          Тип промокода по количеству применений
        </div>

        <b-form-radio-group
          v-model="useType"
          :options="NUMBER_OF_APPLICATIONS"
          class="mb-3"
          value-field="alias"
          text-field="title"
          disabled-field="notEnabled"
          @input="changeUseType"
        />

        <div
          v-if="$v.useType.$error"
          class="mt-2 text-danger small"
        >
          {{ errorsValidation.useType[0] }}
        </div>
      </div>

      <div
        v-if="useType === 'multiple'"
        class="mt-3"
      >
        <div class="mb-2 h6">
          Количество применений
        </div>

        <b-input
          v-model="countUsing"
          placeholder="Количество применений"
          type="number"
          class="mt-2 w-50"
        />

        <div
          v-if="$v.promocode.countUsing.$error"
          class="mt-2 text-danger small"
        >
          {{ errorsValidation.countUsing[0] }}
        </div>
      </div>

      <div class="mt-3">
        <div class="mb-2 h6">
          Количество пользователей
        </div>

        <b-form-radio-group
          v-model="countType"
          :options="COUNT_TYPES"
          class="mb-3"
          value-field="alias"
          text-field="title"
          disabled-field="notEnabled"
          @input="changeCountType"
        />

        <div v-if="countType === 'multiple'">
          <b-input
            v-model.trim="$v.promocode.countUsers.$model"
            type="number"
            placeholder="Количество"
            class="w-50 mt-2"
          />

          <div
            v-if="$v.promocode.countUsers.$error"
            class="mt-2 text-danger small"
          >
            {{ errorsValidation.countUsers[0] }}
          </div>
        </div>

        <div
          v-if="$v.countType.$error"
          class="mt-2 text-danger small"
        >
          {{ errorsValidation.countType[0] }}
        </div>
      </div>

      <div class="mt-3">
        <div class="mb-2 h6">
          Покрытие специализаций
        </div>

        <b-form-radio-group
          v-model="specType"
          :options="SPEC_TYPES"
          class="mb-3"
          value-field="alias"
          text-field="title"
          disabled-field="notEnabled"
          @input="changeSpecType"
        />

        <div
          v-if="$v.specType.$error"
          class="mt-2 text-danger small"
        >
          {{ errorsValidation.specType[0] }}
        </div>
      </div>

      <div
        v-if="specType === 'use'"
        class="mt-2"
      >
        <CombinedTablesWithSearch
          :uniq-id="uniqId"
          title="Выбранные специализации"
          search-placeholder="Поиск по названию специализации"
          :selected-items-list="promocode.specializations"
          :items-list="specializationList"
          @onAdd="onSpecialisationAdd"
          @onRemove="onSpecialisationRemove"
        >
          <template #not-found-text>
            <small>Специализация не найдена</small>
          </template>
          <template #enter-text>
            <small>Введите название специализации</small>
          </template>
        </CombinedTablesWithSearch>
      </div>

      <div
        v-if="specType === 'doc'"
        class="mt-2"
      >
        <template>
          <CombinedTablesWithSearch
            :uniq-id="uniqId"
            title="Выбранные врачи"
            search-placeholder="Поиск по Фамилия/Имя/Отчество"
            :selected-items-list="promocode.doctors"
            :items-list="doctors"
            @onAdd="onDoctorAdd"
            @onRemove="onDoctorRemove"
          >
            <template #not-found-text>
              <small>Врач не найден</small>
            </template>
            <template #enter-text>
              <small>Начните вводить Фамилию/Имя/Отчество</small>
            </template>
          </CombinedTablesWithSearch>
        </template>

        <hr class="my-3">

        <template>
          <CombinedTablesWithSearch
            :uniq-id="uniqId"
            title="Выбранные клиники"
            search-placeholder="Поиск названию клиники"
            :selected-items-list="promocode.clinics"
            :items-list="clinics"
            @onAdd="onClinicAdd"
            @onRemove="onClinicRemove"
          >
            <template #not-found-text>
              <small>Клиника не найдена</small>
            </template>
            <template #enter-text>
              <small>Начните вводить название клиники</small>
            </template>
          </CombinedTablesWithSearch>
        </template>
      </div>

      <div class="mt-3">
        <div class="mb-2 h6">
          Комментарий
        </div>

        <b-form-textarea
          id="comments"
          v-model="$v.promocode.comment.$model"
          placeholder="Введите комментарий"
          rows="3"
          max-rows="6"
        />
      </div>

      <div
        v-if="$v.promocode.comment.$error"
        class="mt-2 text-danger small"
      >
        {{ errorsValidation.comment[0] }}
      </div>
    </template>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  isValid,
  parseISO,
  formatISO,
} from '@evd3v/date-fns';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mixinRoles } from '@/mixins';
import { showValidationErrorMessage } from '@/helpers/messages';
import { v4 as uuidv4 } from 'uuid';

import { clinicService } from '@/services';

import { BaseDatePicker } from '@/components/base';
import CombinedTablesWithSearch from '@/components/common/Tables/CombinedTablesWithSearch';

import {
  SPEC_TYPES,
  COUNT_TYPES,
  DISCOUNT_TYPE,
  SIGN_OF_DEPOSIT,
  PROMO_CODES_TYPES,
  NUMBER_OF_APPLICATIONS,
  PROMO_CODE_VALIDITY_PREIOD,
} from '@/helpers/consts';

export default {
  name: 'PromocodeEditModal',
  components: {
    BaseDatePicker,
    CombinedTablesWithSearch,
  },
  mixins: [validationMixin, mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    promocodeId: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Добавление промокода',
    },
  },

  data() {
    return {
      SPEC_TYPES,
      COUNT_TYPES,
      DISCOUNT_TYPE,
      SIGN_OF_DEPOSIT,
      PROMO_CODES_TYPES,
      NUMBER_OF_APPLICATIONS,
      PROMO_CODE_VALIDITY_PREIOD,
      isOpen: true,
      isLoading: false,
      isSaving: false,
      generatePromocodes: false,
      promocodesCount: null,
      promocodeType: null,
      promocodeQueue: 0,
      termType: null,
      useType: null,
      countType: null,
      specType: null,
      priceType: null,
      secondarySale: null,
      thirdSale: null,
      specializationList: [],
      clinics: [],
      doctors: [],
      uniqId: null,
      promocode: {
        id: 0,
        code: '',
        comment: '',
        isPerc: false,
        isFixed: false,
        startDate: null,
        endDate: null,
        countUsing: null,
        countUsers: null,
        specializations: [],
        doctors: [],
        clinics: [],
        valuePrice: null,
        secondaryPrice: null,
        thirdPrice: null,
        firstUse: null,
        secondaryUse: null,
        thirdUse: null,
        attribute: null,
        status: null,
      },
    };
  },
  validations() {
    return {
      promocodesCount: {
        required: this.generatePromocodes ? required : () => true,
        isValid: this.generatePromocodes ? (count) => count > 0 : () => true,
      },
      promocode: {
        code: {
          required: this.generatePromocodes ? () => true : required,
          isValid: this.generatePromocodes
            ? () => true
            : (code) => code && code.split(' ').length === 1 && !/[а-яА-ЯЁё]/.test(code) && !Number.isInteger(+code),
        },
        comment: {
          required,
        },
        isPerc: {
          required,
        },
        isFixed: {
          required,
        },
        startDate: {
          required,
          isValid,
        },
        endDate: {
          required: (value) => {
            if (this.termType !== 'actual') return true;
            return value != null;
          },
          isValid: (value) => {
            if (this.termType !== 'actual') return true;
            return value >= 0;
          },
        },
        countUsing: {
          required: (value) => {
            if (this.useType === 'limitless') return true;
            return value != null;
          },
          isValid: (value) => {
            if (this.useType === 'limitless') return true;
            return value > 0;
          },
        },
        countUsers: {
          required: (value) => {
            if (this.countType === 'limitless') return true;
            return value != null;
          },
          isValid: (value) => {
            if (this.countType === 'limitless') return true;
            return value > 0;
          },
        },
        // countUsers: {
        //   required,
        //   isValid: (value) => value > 0,
        // },
        valuePrice: {
          required,
          isValid: (value) => value >= 0,
        },
        firstUse: {
          required: (value) => {
            if (this.priceType !== 'dynamic') return true;
            return value != null;
          },
        },
        secondaryUse: {
          required: (value) => {
            if (this.priceType !== 'dynamic' || !this.secondarySale) return true;
            return value != null;
          },
        },
        thirdUse: {
          required: (value) => {
            if (this.priceType !== 'dynamic' || !this.thirdSale) return true;
            return value != null;
          },
        },
        secondaryPrice: {
          required: (value) => {
            if (this.priceType !== 'dynamic' || !this.secondarySale) return true;
            return value != null;
          },
        },
        thirdPrice: {
          required: (value) => {
            if (this.priceType !== 'dynamic' || !this.thirdSale) return true;
            return value != null;
          },
        },
        attribute: {
          required: (value) => value != null,
        },
        status: {
          required: this.promocode.attribute === 2 ? required : () => true,
        },
      },
      promocodeType: {
        required,
      },
      termType: {
        required,
      },
      useType: {
        required,
      },
      countType: {
        required,
      },
      specType: {
        required,
      },
      priceType: {
        required,
      },
    };
  },
  computed: {
    code: {
      get() {
        return this.promocode.code;
      },
      set(value) {
        this.$v.promocode.code.$model = value.toUpperCase();
      },
    },
    countUsing: {
      get() {
        return this.promocode.countUsing;
      },
      set(value) {
        // this.promocode.countUsing = value >= 0 ? value : 0;
        this.$v.promocode.countUsing.$model = value >= 0 ? value : 0;
      },
    },
    status: {
      get() {
        return this.promocode.attribute;
      },
      set(value) {
        this.$v.promocode.attribute.$model = value;
        this.$v.promocode.status.$model = null;
      },
    },
    errorsValidation() {
      const errors = {};

      errors.code = [];
      if (!this.$v.promocode.code.required) {
        errors.code.push('Поле не может быть пустым');
      }
      if (!this.$v.promocode.code.isValid) {
        errors.code.push('Некорректное значение поля');
      }

      errors.promocodesCount = [];
      if (!this.$v.promocodesCount.required) {
        errors.promocodesCount.push('Поле не может быть пустым');
      }
      if (!this.$v.promocodesCount.isValid) {
        errors.promocodesCount.push('Некорректное значение поля');
      }

      errors.promocodeType = [];
      if (!this.$v.promocodeType.required) {
        errors.promocodeType.push('Выберите один из вариантов');
      }

      errors.attribute = [];
      if (!this.$v.promocode.attribute.required) {
        errors.attribute.push('Выберите один из вариантов');
      }

      errors.status = [];
      if (!this.$v.promocode.status.required) {
        errors.status.push('Выберите один из вариантов');
      }

      errors.termType = [];
      if (!this.$v.termType.required) {
        errors.termType.push('Выберите один из вариантов');
      }

      errors.specType = [];
      if (!this.$v.specType.required) {
        errors.specType.push('Выберите один из вариантов');
      }

      errors.useType = [];
      if (!this.$v.useType.required) {
        errors.useType.push('Выберите один из вариантов');
      }

      errors.countType = [];
      if (!this.$v.countType.required) {
        errors.countType.push('Выберите один из вариантов');
      }

      errors.priceType = [];
      if (!this.$v.priceType.required) {
        errors.priceType.push('Выберите один из вариантов');
      }

      errors.comment = [];
      if (!this.$v.promocode.comment.required) {
        errors.comment.push('Поле не может быть пустым');
      }

      errors.countUsing = [];
      if (!this.$v.promocode.countUsing.required) {
        errors.countUsing.push('Поле не может быть пустым');
      }
      if (!this.$v.promocode.countUsing.isValid) {
        errors.countUsing.push('Некорректное значение поля');
      }

      errors.countUsers = [];
      if (!this.$v.promocode.countUsers.required) {
        errors.countUsers.push('Поле не может быть пустым');
      }
      if (!this.$v.promocode.countUsers.isValid) {
        errors.countUsers.push('Некорректное значение поля');
      }

      errors.valuePrice = [];
      if (!this.$v.promocode.valuePrice.required) {
        errors.valuePrice.push('Поле не может быть пустым');
      }
      if (!this.$v.promocode.valuePrice.isValid) {
        errors.valuePrice.push('Некорректное значение поля');
      }

      errors.startDate = [];
      if (!this.$v.promocode.startDate.required) {
        errors.startDate.push('Поле не может быть пустым');
      }
      if (!this.$v.promocode.startDate.isValid) {
        errors.startDate.push('Некорректное значение поля');
      }

      errors.endDate = [];
      if (!this.$v.promocode.endDate.required) {
        errors.endDate.push('Поле не может быть пустым');
      }
      if (!this.$v.promocode.endDate.isValid) {
        errors.endDate.push('Некорректное значение поля');
      }

      errors.secondaryPrice = [];
      if (!this.$v.promocode.secondaryPrice.required) {
        errors.secondaryPrice.push('Поле не может быть пустым');
      }
      errors.thirdPrice = [];
      if (!this.$v.promocode.thirdPrice.required) {
        errors.thirdPrice.push('Поле не может быть пустым');
      }
      errors.firstUse = [];
      if (!this.$v.promocode.firstUse.required) {
        errors.firstUse.push('Поле не может быть пустым');
      }
      errors.secondaryUse = [];
      if (!this.$v.promocode.secondaryUse.required) {
        errors.secondaryUse.push('Поле не может быть пустым');
      }
      errors.thirdUse = [];
      if (!this.$v.promocode.thirdUse.required) {
        errors.thirdUse.push('Поле не может быть пустым');
      }

      return errors;
    },
    consultations() {
      return [
        {
          id: 1,
          inputAdded: true,
          consultationsKey: 'firstUse',
          priceKey: 'valuePrice',
        },
        {
          id: 2,
          inputAdded: this.secondarySale,
          addInputAddedMethod: this.addSecondarySale,
          consultationsKey: 'secondaryUse',
          priceKey: 'secondaryPrice',
        },
        {
          id: 3,
          inputAdded: this.thirdSale,
          addInputAddedMethod: this.addThirdSale,
          consultationsKey: 'thirdUse',
          priceKey: 'thirdPrice',
        },
      ];
    },
  },
  async created() {
    if (this.promocodeId) {
      try {
        this.isLoading = true;
        const [promocode] = await Promise.all([
          this.$store.dispatch(this.$types.PROMOCODE_FETCH, this.promocodeId),
        ]);
        this.promocode = {
          ...promocode,
          startDate: promocode.startDate ? parseISO(promocode.startDate) : null,
          endDate: promocode.endDate ? parseISO(promocode.endDate) : null,
        };
        if (promocode.type) {
          this.promocodeQueue = promocode.type;
        }
        // set Promocode Type
        if (promocode.isAbsolute) {
          this.promocodeType = 'absolute';
        } else if (promocode.isFixed) {
          this.promocodeType = 'fixed';
        } else if (promocode.isPerc) {
          this.promocodeType = 'perc';
        }
        // set Promocode Term Type
        if (promocode.startDate && promocode.endDate) {
          this.termType = 'actual';
        } else if (promocode.startDate && !promocode.endDate) {
          this.termType = 'limitless';
        }
        // set Promocode Use Type
        if (promocode.countUsing > 1) {
          this.useType = 'multiple';
        } else if (promocode.countUsing === 1) {
          this.useType = 'single';
        } else {
          this.useType = 'limitless';
        }
        // set Promocode Count Type
        if (promocode.countUsers > 0) {
          this.countType = 'multiple';
        } else {
          this.countType = 'limitless';
        }
        // set Promocode Price Type
        if (promocode.firstUse) {
          this.priceType = 'dynamic';
        } else {
          this.priceType = 'fixed';
        }
        // set Sales
        if (promocode.secondaryPrice || promocode.secondaryUse) {
          this.secondarySale = true;
        }
        if (promocode.thirdPrice || promocode.thirdUse) {
          this.thirdSale = true;
        }
        // set Promocode Spec Type
        if (promocode.specializations && promocode.specializations.length) {
          this.specType = 'use';
        } else if (promocode.doctors && promocode.doctors.length) {
          this.specType = 'doc';
        } else {
          this.specType = 'none';
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    }

    this.clinics = (await clinicService.getListNames()).map(({
      id,
      name,
      cityId,
      clinicAddress,
    }) => ({ id, name: `${name}, ${clinicAddress}`, cityId }));
    this.specializationList = await this.fetchSpecializations('');
    this.doctors = await this.fetchDoctors('');
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    changePromocodeType(type) {
      this.promocode.isFixed = false;
      this.promocode.isPerc = false;
      switch (type) {
        case 'fixed':
          this.promocode.isFixed = true;
          break;
        case 'perc':
          this.promocode.isPerc = true;
          break;
        default:
          break;
      }
    },
    changeUseType(type) {
      switch (type) {
        case 'single':
          this.promocode.countUsing = 1;
          break;
        case 'multiple':
          this.promocode.countUsing = 0;
          break;
        case 'limitless':
          this.promocode.countUsing = null;
          break;
        default:
          break;
      }
    },
    changeCountType(type) {
      switch (type) {
        case 'multiple':
          this.promocode.countUsers = 0;
          break;
        case 'limitless':
          this.promocode.countUsers = null;
          break;
        default:
          break;
      }
    },
    changePriceType(type) {
      switch (type) {
        case 'fixed':
          this.promocode.firstUse = null;
          this.promocode.secondaryUse = null;
          this.promocode.thirdUse = null;
          this.promocode.secondaryPrice = null;
          this.promocode.thirdPrice = null;
          this.secondarySale = false;
          this.thirdSale = false;
          break;
        case 'dynamic':
          break;
        default:
          break;
      }
    },
    changeSpecType(type) {
      switch (type) {
        case 'use':
          this.promocode.doctors = null;
          this.promocode.clinics = null;
          this.promocode.specializations = [];
          this.updateInput();
          break;
        case 'doc':
          this.promocode.specializations = null;
          this.promocode.doctors = [];
          this.promocode.clinics = [];
          this.updateInput();
          break;
        case 'none':
          this.promocode.specializations = null;
          this.promocode.doctors = null;
          this.promocode.clinics = null;
          break;
        default:
          break;
      }
    },
    changeTermType(type) {
      switch (type) {
        case 'actual':
          this.promocode.startDate = null;
          this.promocode.endDate = null;
          break;
        case 'limitless':
          this.promocode.startDate = new Date();
          this.promocode.endDate = null;
          break;
        default:
          break;
      }
    },
    async onClickSave() {
      this.$v.$touch();
      if (this.$v.$error) {
        console.log(this.$v.error);

        showValidationErrorMessage();
        return;
      }

      this.isSaving = true;
      const promocode = {
        ...this.promocode,
        startDate: this.promocode.startDate ? formatISO(this.promocode.startDate) : null,
        endDate: this.promocode.endDate ? formatISO(this.promocode.endDate) : null,
        specializations: this.promocode.specializations ? this.promocode.specializations.map((spec) => {
          if (spec.id) {
            return spec.id;
          }
          return spec;
        }) : [],
        doctors: this.promocode.doctors ? this.promocode.doctors.map((doc) => {
          if (doc.id) {
            return doc.id;
          }
          return doc;
        }) : [],
        clinicIds: this.promocode.clinics ? this.promocode.clinics.map((clinic) => {
          if (clinic.id) {
            return clinic.id;
          }
          return clinic;
        }) : [],
        type: this.promocodeQueue === 0 ? 'Consultation' : 'DutyConsultation',
      };

      try {
        if (this.promocodeId) {
          await this.$store.dispatch(this.$types.PROMOCODE_UPDATE, promocode);
        } else if (this.generatePromocodes) {
          await this.$store.dispatch(this.$types.PROMOCODE_GENERATE, { count: this.promocodesCount, promocode });
        } else {
          await this.$store.dispatch(this.$types.PROMOCODE_CREATE, promocode);
          this.$emit('search');
        }

        this.$store.dispatch(this.$types.PROMOCODES_FETCH, true);
        this.onClose();
      } catch (e) {
        console.warn(e);
      } finally {
        this.isSaving = false;
      }
    },
    async fetchSpecializations(query) {
      const specialization = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, query);
      return specialization.sort((a, b) => a.name.localeCompare(b.name));
    },
    async fetchDoctors({ query, skip, take }) {
      const doctors = await this.$store.dispatch(this.$types.DOCTORS_FETCH, {
        skip,
        take,
        query,
        save: false,
      });
      return doctors.map((doctor) => ({ ...doctor, name: doctor.fullName }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    onSpecialisationAdd(specId) {
      if (this.promocode.specializations.find((s) => s.id === specId)) return;

      const specialization = this.specializationList.find((s) => s.id === specId);
      if (specialization) this.promocode.specializations.push(specialization);
    },
    onSpecialisationRemove(specId) {
      this.promocode.specializations = this.promocode.specializations.filter((spec) => spec.id !== specId);
    },
    onDoctorAdd(doctorId) {
      if (this.promocode.doctors.find((d) => d.id === doctorId)) return;

      const doctor = this.doctors.find((d) => d.id === doctorId);
      if (doctor) this.promocode.doctors.push(doctor);
    },
    onDoctorRemove(doctorId) {
      this.promocode.doctors = this.promocode.doctors.filter((d) => d.id !== doctorId);
    },
    onClinicAdd(clinicId) {
      if (this.promocode.clinics.find((c) => c.id === clinicId)) return;

      const clinic = this.clinics.find((c) => c.id === clinicId);
      if (clinic) this.promocode.clinics.push(clinic);
    },
    onClinicRemove(clinicId) {
      this.promocode.clinics = this.promocode.clinics.filter((c) => c.id !== clinicId);
    },
    addSecondarySale() {
      this.secondarySale = true;
    },
    addThirdSale() {
      this.thirdSale = true;
    },
    updateInput() {
      this.uniqId = uuidv4();
    },
  },
};
</script>

<style lang="scss" scoped>

.checkbox-queue {
 margin: 27px 0px 0px 27px;
}

.checkbox-queue-2 {
  margin: 32px 0px 0px 27px;
}
</style>
